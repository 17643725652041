@import './_color';
@import './_weight';

.red {
  color: $primary-color;
  margin-left: 0.5rem;
  font-weight: $w-500;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.row-simple {
  display: flex;
  flex-direction: row;
}

.center {
  display: flex;
  justify-content: center;
}

.title {
  color: $text-color;
}

.red-button {
  background-color: $primary-color;
}

.red-button-text {
  color: $primary-color;
}

.blue-button {
  background-color: $secondary-color;
}

.blue-button-text {
  color: $secondary-color;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $primary-color !important;
}

.mat-step-header{

  .mat-step-icon{
    background: white !important;
    padding: 1px;
    height: 1rem;
    width: 1rem;
    border: 5.2px double #f2eeee !important;
    
    .mat-step-icon-content{
      // display: none;
    }
  }
  
  .mat-step-icon-selected,
  .cdk-program-focused {
    border: 5.2px double $primary-color !important;
  }

}