//colors primarys
$primary-color: #D34659;
$secondary-color: #0F3B7A;

//complementary
$background-section: #F1F6FF;
$background-color: #f6f6f6;
$background-secondary: #D8E8F5;
$background-footer: #F4F4F4;
$background-checkout: #F5F8FE;
$fondo: #CBD4E4;
$text-color: #475261;
$mat-text-color: rgba(0, 0, 0, 0.87);

//extras
$text: #333333;